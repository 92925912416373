<template lang="pug">

v-card
  v-card-title Retail List 2.0
    v-spacer
    v-text-field(single-line hide-details clearable prepend-inner-icon="mdi-magnify" label="Search" v-model="search")
    v-spacer
    edit-unit(@updateSearch="updateSearch($event)")
  v-data-table(:items="inventory" disable-pagination=true hide-default-footer :headers="headers" :search="search" group-by="category")
    template(v-slot:item.actions="{ item }")
      v-icon(color="blue" @click="navToUnit(item)") preview
</template>

<script>
import { mapGetters } from 'vuex'
import editUnit from '@/components/edit-unit'

export default {
  name: 'app',
  data () {
    return {
      headers: [
        { text: 'Stock #', value: 'stock', groupable: false },
        { text: 'VIN', value: 'vin', groupable: false },
        { text: 'Cost', value: 'k', groupable: false },
        { text: 'Make', value: 'make' },
        { text: 'Model', value: 'model' },
        { text: 'Width', value: 'width', groupable: false },
        { text: 'Length', value: 'length', groupable: false },
        { text: 'Color', value: 'color', groupable: false },
        { text: 'Features', value: 'features', groupable: false },
        { text: 'MSRP', value: 'msrp', groupable: false },
        { text: 'Price', value: 'price', groupable: false },
        { text: 'Actions', value: 'actions', sortable: false, groupable: false }
      ],
      view: 'categories',
      selected: undefined,
      units: []
    }
  },
  methods: {
    navToUnit (unit) {
      this.$router.push({ path: `/inventory/${unit.id}` })
    },
    updateSearch (search) {
      this.search = search
    }
  },
  computed: {
    search: {
      get () {
        return this.$store.state.search
      },
      set (value) {
        this.$store.commit('updateSearch', value)
      }
    },
    ...mapGetters([
      'inventory'
    ])
  },
  components: {
    editUnit
  }
}
</script>

<style lang="stylus">
</style>
